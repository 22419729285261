<template>
    <div class="actions">
      <a
        href="javascript:void(0)"
        class="btn btn-primary btn-sm"
        data-toggle="modal"
        data-target="#update-time-slot"
        @click.prevent="setSelected"
      > 
        <i class="icofont icofont-pencil" />
      </a>
      <a 
        href="javascript:void(0)" 
        class="btn btn-success btn-sm" 
        v-if="!isActive"
        @click.prevent="updating"
        >
        <i class="icofont icofont-play"></i>
      </a>
      <a 
        href="javascript:void(0)" 
        class="btn btn-warning btn-sm" 
        @click.prevent="updating"
        v-else
        >
        <i class="icofont icofont-pause"></i>
      </a>
      <a
        href="javascript:void(0)"
        class="btn btn-danger btn-sm"
        @click.prevent="deleting"
      > 
        <i class="icofont icofont-close" />
      </a>
    </div>
  </template>
    
    <script>
    import { mapGetters, mapMutations } from 'vuex';
    import { DELETE_TIME_SLOT, UPDATE_TIME_SLOT } from '../../../graphql/events';
    export default {
        props: {object: {type: Object, required: true}},
        data(){
            return  {
    
            }
        },
        methods: {
            ...mapMutations({
                setSelectedObject: 'SET_SELECTED_OBJECT'
            }),
            setSelected(){
                this.setSelectedObject(this.object)
            },
            deleting(){
                this.$apollo.mutate({
                    mutation: DELETE_TIME_SLOT,
                    variables: {
                        "uid": this.object.uid
                    },
                    update: (data) => {
                        if(data) console.log(`Time slot ${this.object.dayOfWeek} at ${this.object.startTime} deleted successfully`)
                        else console.log(`Error in deletting type event ${this.object.dayOfWeek} - ${this.object.startTime}`)
                    }
                  }).then(() => {
                    
                  })
            },
            updating(){
                this.$apollo.mutate({
                    mutation: UPDATE_TIME_SLOT,
                    variables: {
                        "dayOfWeek": this.object.dayOfWeek,
                        "startTime": this.object.startTime,
                        "active": !this.isActive,
                        "uid": this.object.uid
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Time slot ${this.dayOfWeek} on ${this.object.uid} updated successfully`)
                    }
                }).catch((error) => {
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
      
            }
        },
        computed: {
            ...mapGetters({
    
            }),
            isActive(){
                return this.object && this.object.active
            }
        }
    }
    </script>
    
    <style>
    
    </style>